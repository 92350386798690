import { useContractReader } from "eth-hooks";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import { utils } from "ethers";

export default function Claims({ tx, address, readContracts, writeContracts }) {
  const [pendingClaims, setPendingClaims] = useState(0);
  const [tripsBalance, setTripsBalance] = useState(0);
  const [rareTripsBalance, setRareTripsBalance] = useState(0);
  const [tripTotalSupply, setTripTotalSupply] = useState(0);
  const [isClaimDisabled, setIsClaimDisabled] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);

  const getPendingClaims = async () => {
    try {
      if (readContracts.TripsNFT && readContracts.Claims && address) {
        let rawPendingClaims = await readContracts.Claims.pendingClaims(
          address,
          "0xD8Dab8eFD03F0BB660f01c4fC74eEb1049a45E16",
        );
        let pendingClaimDecimal = Number(rawPendingClaims.toString()) / 10 ** 9; //30,000,000
        setPendingClaims(pendingClaimDecimal);
        if (pendingClaimDecimal > 0) {
          setIsClaimDisabled(false);
        }
      }
    } catch (e) {
      setIsClaimDisabled(true);
      console.log(e);
    }
  };

  useEffect(async () => {
    getPendingClaims();
  }, [readContracts, address]);

  const getRareBalance = async () => {
    try {
      setShowSuccess(false);
      if (readContracts.TripsNFT && readContracts.Claims && address) {
        let rawBalance = await readContracts.TripsNFT.balanceOf(address);
        let balance = Number(rawBalance.toString());
        setTripsBalance(balance);
        let rareTripsCount = 0;
        for (let tokenIndex = 0; tokenIndex < balance; tokenIndex++) {
          let tokenId = await readContracts.TripsNFT.tokenOfOwnerByIndex(address, tokenIndex);
          let isRare = await readContracts.Claims.isRare(tokenId);
          if (isRare) {
            rareTripsCount = rareTripsCount + 1;
          }
        }
        setRareTripsBalance(rareTripsCount);
        let rawTotalSupply = await readContracts.TripsNFT.totalSupply();
        let totalSupply = Number(rawTotalSupply.toString());
        setTripTotalSupply(totalSupply);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(async () => {
    getRareBalance();
  }, [readContracts, address]);

  const claimMemo = async () => {
    try {
      const result = tx(writeContracts.Claims.claim("0xD8Dab8eFD03F0BB660f01c4fC74eEb1049a45E16"), update => {
        if (update && (update.status === "confirmed" || update.status === 1)) {
          console.log(" 🍾 Transaction " + update.hash + " finished!");
          setIsClaimDisabled(true);
          setShowSuccess(true);
          getPendingClaims();
        }
      });
      const finishedResult = await result;
      console.log(finishedResult);
    } catch (error) {
      setIsClaimDisabled(true);
      setShowSuccess(false);
      console.log(error);
    }
  };

  let success;
  if (showSuccess) {
    success = (
      <div className="text-center mt-5">
        <h2 className="text-aqua">SUCCESS!</h2>
        <h5 className="text-white">You have claimed your rewards.</h5>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="row d-flex claim-content">
        <div className="col-12 text-center claim-content">
          <div className="text-muted">
            <h4 className="text-white mt-5">Total Unclaimed Memo</h4>
            <h4 className="text-secondary">{pendingClaims}</h4>
            My TRIPS: {tripsBalance}/{tripTotalSupply} : {parseFloat((tripsBalance / tripTotalSupply) * 100).toFixed(2)}
            % Share
          </div>
          <h6 className="text-muted">Rare TRIPS {rareTripsBalance}</h6>
          <button
            className="btn btn-secondary btn-mint mx-2 my-2"
            disabled={isClaimDisabled}
            onClick={() => {
              claimMemo();
            }}
          >
            Claim
          </button>
          {success}
        </div>
      </div>
    </div>
  );
}
